import moment from "moment"
import memoize from "lodash/memoize"

export const TWO_HOURS = 7200000
export const THIRTY_MINUTES = 1800000
export const ONE_HOUR = 3600000

const SEPTEMBER = 8

export const currentSchoolYear = memoize(() =>
  moment().month() < SEPTEMBER ? moment().subtract(1, "year").year() : moment().year()
)

// export const currentSchoolYear = memoize(() => 2021)
