import { Avatar, Theme, styled } from "@mui/material"

type StyledProps = { theme: Theme }

export const UserAvatarButton = styled("button")(({ theme }) => ({
  border: "none",
  background: "none",
  cursor: "pointer",
  padding: "1rem",
  "&:focus, &:active": {
    outline: "none",
  },
  "&:hover .userAvatar": {
    border: `2px solid ${theme.palette.primary.main}`,
  },
}))

export const UserAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(4),
  height: theme.spacing(4),
  fontSize: "1.2rem",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.grey[900],
  border: `2px solid ${theme.palette.grey[900]}`,
}))
