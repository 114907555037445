import Link from "next/link"
import { useParam, Routes } from "@blitzjs/next"
import React, { FC } from "react"
import FoSLogo from "src/app/core/components/FoSLogo"
import Redirect from "src/app/core/components/Redirect"
import { useMediaQuery, useTheme } from "@mui/material"

type Props = {}

const SchoolLogo: FC<Props> = () => {
  const schoolSlug = useParam("school", "string")
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  if (!schoolSlug) {
    return <Redirect url="/" />
  }

  return (
    <Link href={Routes.ShopIndex({ school: schoolSlug })} style={{ display: "inline-flex" }}>
      <FoSLogo height={isMobile ? 24 : 32} invert />
      {/*<img*/}
      {/*  src={`/schools/logos/${schoolSlug}.png`}*/}
      {/*  srcSet={`/schools/logos/${schoolSlug}@2x.png 2x`}*/}
      {/*  alt="School logo"*/}
      {/*/>*/}
    </Link>
  )
}

export default SchoolLogo
