import React, { FC } from "react"
import { styled, Box, Tooltip /*, useMediaQuery, useTheme*/ } from "@mui/material"
import { useTranslation } from "next-i18next"
// import FoSLogo from "app/core/components/FoSLogo"
// import { ProfileNavButton } from "app/users"
// import { FullWidthContent } from "app/core/components/FullWidthContent"
import { CartIcon } from "src/app/shop/feature-shopping-cart/components/CartIcon"
import { FullWidthContainer } from "src/app/core/components/FullWidthContainer"
import SchoolLogo from "./SchoolLogo"
import ProfileNavButton from "./ProfileNavButton"
import { GalleryNavItem } from "./GalleryNavItem"
import { FaqNavItem } from "./FaqNavItem"
import { ShopNavItem } from "./ShopNavItem"

const Bar = styled("div")(({ theme }) => ({
  height: 56,
  width: "100%",
  display: "flex",
  justifyContent: "center",
  backgroundColor: theme.palette.grey[50],
}))

const BarContent = styled(FullWidthContainer)({
  height: "100%",
  display: "flex",
  flexFlow: "row",
  alignItems: "center",
  justifyContent: "space-between",
  width: "100%",
  padding: "0 2rem",
})

export const Header: FC = () => {
  const { t } = useTranslation("shop")
  // const theme = useTheme()
  // const isXs = useMediaQuery(theme.breakpoints.down("xs"))
  // useEffect(() => {
  //   const bodyEl = document.querySelector('body');
  //   if (bodyEl) bodyEl.style.backgroundColor = 'white';
  // }, []);
  return (
    <Bar>
      <BarContent>
        <Box display="flex" height="100%" alignItems="center">
          <SchoolLogo />
          <Tooltip
            title={t<string>("navHeaderGallery")}
            arrow
            PopperProps={{
              style: { marginTop: -14 },
            }}
          >
            <span style={{ height: "100%", marginLeft: "1rem" }}>
              <GalleryNavItem />
            </span>
          </Tooltip>
          <Tooltip
            title={t<string>("navHeaderShop")}
            arrow
            PopperProps={{
              style: { marginTop: -14 },
            }}
          >
            <span style={{ height: "100%" }}>
              <ShopNavItem />
            </span>
          </Tooltip>
          <Tooltip
            title={t<string>("navHeaderFaq")}
            arrow
            PopperProps={{
              style: { marginTop: -14 },
            }}
          >
            <span style={{ height: "100%" }}>
              <FaqNavItem />
            </span>
          </Tooltip>
        </Box>
        {/*{!isXs && <FoSLogo height={32} invert />}*/}
        <Box display="flex" alignItems="center">
          <Tooltip
            title={t<string>("navHeaderCart")}
            arrow
            PopperProps={{
              style: { marginTop: -4 },
            }}
          >
            <span>
              <CartIcon />
            </span>
          </Tooltip>
          {/*<Tooltip*/}
          {/*  title={t<string>("header.changeLanguage")}*/}
          {/*  arrow*/}
          {/*  PopperProps={{*/}
          {/*    style: { marginTop: -4 },*/}
          {/*  }}*/}
          {/*>*/}
          {/*  <span>*/}
          {/*    <LanguageSelector />*/}
          {/*  </span>*/}
          {/*</Tooltip>*/}
          <Tooltip
            title={t<string>("navHeaderUser")}
            arrow
            PopperProps={{
              style: { marginTop: -12 },
            }}
          >
            <span>
              <ProfileNavButton />
            </span>
          </Tooltip>
        </Box>
      </BarContent>
    </Bar>
  )
}
