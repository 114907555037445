import Link from "next/link"
import { useParam, Routes } from "@blitzjs/next"
import React, { FC } from "react"
import { Button } from "@mui/material"
import { useTranslation } from "next-i18next"

export const FaqNavItem: FC = () => {
  const { t } = useTranslation("shop")
  const slug = useParam("school", "string") || ""

  return (
    <Link href={Routes.ShopFAQPage({ school: slug })} passHref legacyBehavior>
      <Button color="inherit" variant="text" style={{ fontWeight: 200, height: "100%" }}>
        {t("navHeaderFaqText")}
      </Button>
    </Link>
  )
}
