import { useRouter } from "next/router"
import { FC, useEffect } from "react"
import { UrlObject } from "url"

interface TransitionOptions {
  shallow?: boolean
}
type Url = UrlObject | string
type Props = {
  url: Url
  as?: Url
  options?: TransitionOptions
}
const Redirect: FC<Props> = ({ url, as, options }) => {
  const { push } = useRouter()

  useEffect(() => {
    void push(url, as, options)
  }, [url, as, options, push])

  return null
}

export default Redirect
