import { useQuery } from "@blitzjs/rpc";
import React, { FC } from "react"
import CartOutline from "mdi-material-ui/CartOutline"
import { Badge, Button, ButtonProps } from "@mui/material"
import getShoppingCart from "../queries/getShoppingCart"
import { ToCheckoutLink } from "./ToCheckoutLink"
import { ONE_HOUR } from "src/app/core/libs/dateTime"

type Props = Partial<ButtonProps> & {
  withoutLink?: boolean
}
export const CartIcon: FC<Props> = ({ withoutLink, ...buttonProps }) => {
  const [shoppingCart, { isFetching }] = useQuery(
    getShoppingCart,
    {},
    { cacheTime: ONE_HOUR, refetchOnWindowFocus: false }
  )

  const button = (
    // @ts-ignore
    <Button
      component="a"
      variant="text"
      disabled={isFetching || !shoppingCart || !shoppingCart?.lines.length}
      color="inherit"
      {...buttonProps}
    >
      {shoppingCart?.lines.length ? (
        <Badge badgeContent={shoppingCart.lines.length} color="primary">
          <CartOutline />
        </Badge>
      ) : (
        <CartOutline />
      )}
    </Button>
  )

  if (withoutLink) return button

  return <ToCheckoutLink>{button}</ToCheckoutLink>
}
