import { useMutation } from "@blitzjs/rpc"
import Link from "next/link"
import React, { FC, useState } from "react"
import {
  Avatar,
  Badge,
  Popover,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  styled,
} from "@mui/material"
import UserIcon from "mdi-material-ui/Account"
import LogoutIcon from "mdi-material-ui/LogoutVariant"
import FormatListBulletedIcon from "mdi-material-ui/FormatListBulleted"
import { useCurrentUser } from "src/app/core/hooks/useCurrentUser"
import logout from "src/app/auth/mutations/logout"
import { useTranslation } from "next-i18next"
import { UserAvatar, UserAvatarButton } from "src/app/core/components/Avatar"
import { getInitials } from "src/app/core/libs/string"
import { Routes, useParam } from "@blitzjs/next"

const SmallAvatar = styled(Avatar)(({ theme }) => ({
  width: theme.spacing(2),
  height: theme.spacing(2),
  border: `1px solid ${theme.palette.background.paper}`,
  fontSize: "1rem",
}))

const Button = UserAvatarButton

const ProfileNavButton: FC = () => {
  const { t } = useTranslation()
  const { t: tShop } = useTranslation("shop")
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  const currentUser = useCurrentUser()
  const [doLogout] = useMutation(logout)
  const school = useParam("school", "string") || ""

  const handleClickLogout = () => doLogout().then(() => window.location.reload())

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const userAvatar = (
    <UserAvatar alt={currentUser?.name || ""} className="userAvatar">
      {getInitials(currentUser?.name)}
    </UserAvatar>
  )

  return (
    <>
      <Button onClick={handleClick}>
        <Badge
          overlap="circular"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          badgeContent={
            <SmallAvatar alt={currentUser?.name || ""}>
              <UserIcon fontSize="inherit" />
            </SmallAvatar>
          }
        >
          {userAvatar}
        </Badge>
      </Button>

      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
        elevation={5}
      >
        <List>
          <ListItem>
            <ListItemIcon>{userAvatar}</ListItemIcon>
            <ListItemText primary={currentUser?.name} secondary={currentUser?.email} />
          </ListItem>
          <Link href={Routes.ShopOrdersIndex({ school })} passHref onClick={handleClose}>
            <ListItemButton>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText primary={tShop("navHeaderOrders")} />
            </ListItemButton>
          </Link>
          <Divider />
          <ListItemButton onClick={handleClickLogout}>
            <ListItemIcon>
              <LogoutIcon />
            </ListItemIcon>
            <ListItemText primary={t("authSignOut")} />
          </ListItemButton>
        </List>
      </Popover>
    </>
  )
}

export default ProfileNavButton
