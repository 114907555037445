import Link from "next/link"
import { useParam, Routes } from "@blitzjs/next"
import React, { FC } from "react"
import { Button } from "@mui/material"
import { useTranslation } from "next-i18next"
import { useActiveSchoolYear } from "../shared/hooks/useActiveSchoolYear"

export const ShopNavItem: FC = () => {
  const { t } = useTranslation("shop")
  const year = useActiveSchoolYear()
  const slug = useParam("school", "string") || ""

  return (
    <Link href={Routes.ShopYearWebShopPage({ school: slug, year })} passHref legacyBehavior>
      <Button color="inherit" variant="text" style={{ fontWeight: 200, height: "100%" }}>
        {t("navHeaderShopText")}
      </Button>
    </Link>
  )
}
