import Head from "next/head"
import { BlitzLayout, Routes } from "@blitzjs/next"
import { Suspense } from "react"
import { Header } from "./Header"
import PageLoader from "src/app/core/components/PageLoader"
import { SchoolGate } from "./SchoolGate"

const Layout: BlitzLayout<{ title?: string }> = ({ title, children }) => {
  return (
    <>
      <Head>
        <title>{title || "Foto's Op School"}</title>
        <link
          rel="icon"
          href="/Users/david/projects/hethuisvanvier/fotosopschool/public/favicon.ico"
        />
      </Head>

      <Header />

      <main>
        <Suspense fallback={<PageLoader />}>
          <SchoolGate>{children}</SchoolGate>
        </Suspense>
        {/*<Chat />*/}
      </main>
    </>
  )
}

// @TODO dynamic
Layout.authenticate = { redirectTo: Routes.LoginPage() }
// Layout.redirectAuthenticatedTo = ({ session }) => {
//   if (session.orgTypes?.includes("PHOTOGRAPHER")) return false
//
//   return Routes.Logout()
// }

export default Layout
