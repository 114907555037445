export const getInitials = (str?: string | null) =>
  str
    ?.split(" ")
    .slice(0, 2)
    .map((part) => part[0])
    .join("") || ""

export const getFileName = (str?: string | null): string => str?.replace(/^.*[\\\/]/, "") || ""

// Remove accents from string
export const normalize = (str?: string | null): string =>
  str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "") || ""
