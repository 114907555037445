import Link, { LinkProps } from "next/link"
import { Routes, useParam } from "@blitzjs/next"
import { FC } from "react"

export const ToCheckoutLink: FC<Partial<LinkProps>> = ({ children }) => {
  const school = useParam("school", "string")
  return (
    <Link href={Routes.ShopCheckoutIndex({ school: school || "" })} passHref legacyBehavior>
      {children}
    </Link>
  )
}
