import { FC, PropsWithChildren, useEffect } from "react"
import { Routes, useParam } from "@blitzjs/next"
import { useSchoolForUser } from "../shared/hooks/useSchoolForUser"
import { useRouter } from "next/router"

export const SchoolGate: FC<PropsWithChildren<{}>> = ({ children }) => {
  const schoolSlug = useParam("school", "string")
  const school = useSchoolForUser()
  const { replace } = useRouter()

  useEffect(() => {
    if (school?.slug && schoolSlug !== school.slug) {
      void replace(Routes.ShopIndex({ school: school.slug }))
    }
  }, [schoolSlug, school?.slug, replace])

  return <>{children}</>
}
